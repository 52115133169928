




































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { RolesNames } from "@/helpers";

@Component
class UserItem extends Vue {
  @Prop() item!: {
    password: string;
  };

  @Prop() onSave!: Function;

  passwordLength = 0;

  containsEightCharacters = false;

  containsNumber = false;

  containsUppercase = false;

  containsSpecialCharacter = false;

  validPassword = false;

  roleNames = RolesNames;

  constructor() {
    super();
  }

  data() {
    return {
      // roleNames: this.roleNames,
    };
  }

  checkPassword() {
    this.passwordLength = this.item.password.length;
    const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (this.passwordLength > 8) {
      this.containsEightCharacters = true;
    } else {
      this.containsEightCharacters = false;
    }

    this.containsNumber = /\d/.test(this.item.password);
    this.containsUppercase = /[A-Z]/.test(this.item.password);
    this.containsSpecialCharacter = format.test(this.item.password);

    if (
      this.containsEightCharacters === true &&
      this.containsSpecialCharacter === true &&
      this.containsUppercase === true &&
      this.containsNumber === true
    ) {
      this.validPassword = true;
    } else {
      this.validPassword = false;
    }
  }
}
export default UserItem;
